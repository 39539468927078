export const baseURL = process.env.REACT_APP_API_ENDPOINT

export const version = require('../../package.json').version

export const sendBirdConfig = {
  appId: process.env.REACT_APP_SENDBIRD_APP_ID,
  // apiToken: process.env.REACT_APP_SENDBIRD_APP_TOKEN,
  apiUrl: 'https://api.sendbird.com'
}

export const roleLevel = {
  User: 1 << 0,
  Assistant: 1 << 1,
  Service_Provider: 1 << 2,
  Coach: 1 << 3,
  Expert: 1 << 4,
  InternalAdmin: 1 << 5,
  Manager: 1 << 10
}

export const userType = {
  Core: 0,
  Non_Core: 1,
  Test_User: 2,
  Partners: 3,
  Archive: 6,
  Prospect: -1
}

export const pattern = {
  email: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/,
  integer: /^[0-9]*[1-9][0-9]*$/,
  floatNumber: /^(-)?\d+(\.\d{1,2})?$/,
  positiveNumber: /^\d+((\.\d{1,2})?|\.)$/,
  positiveNumberWithOneDigits: /^\d+((\.\d{1})?|\.)$/,
  usPhoneNumber: /^(1?|(1-)?)\d{10}$/,
  E164Number: /^\+[1-9]\d{4,14}$/,
  cvvNumber: /^\d{3,4}$/,
  creditCardExpDate: /^\d\d\/\d\d$/,
  creditCardNumber: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
  // eslint-disable-next-line no-useless-escape
  matchBoldQuestionTitle: /\*{2}[^\*]+\*{2}/gi,
  DomainRegex: /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/gim,
  URLRegex: /(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?/gim,
  URL: /((https|http|ftp|rtsp|mms)?:\/\/)[^<|"|\\]+/gim,
  // eslint-disable-next-line no-useless-escape
  phoneOrFaxNumber: /\+?[0-9]+(\-?[0-9]+)*$/
  // image: /<img [^>]*src=['"][^'"]+)[^>]*>/g
}

export const workoutPillarColors = {
  sleep: {
    primary: '#5C00D1',
    light: '#E2CCFF'
  },
  nutrition: {
    primary: '#7BAD2D',
    light: '#E9F4D7'
  },
  exercise: {
    primary: '#FF6B00',
    light: '#FEE2CD'
  }
}

// #40C47C - Emerald
// #7BAD2D - Green
// #FFB800 - Yellow
// #FF6B00 - Orange
// #DF0726 - Red
export const RangeColors = {
  emerald: '#40C47C',
  green: '#7BAD2D',
  blue: '#86C2D8',
  yellow: '#FFB800',
  orange: '#FF6B00',
  red: '#DF0726'
}

const colorArr = [
  {
    key: 'emerald',
    value: RangeColors.emerald
  },
  {
    key: 'green',
    value: RangeColors.green
  },
  {
    key: 'blue',
    value: RangeColors.blue
  },
  {
    key: 'yellow',
    value: RangeColors.yellow
  },
  {
    key: 'orange',
    value: RangeColors.orange
  },
  {
    key: 'red',
    value: RangeColors.red
  }
]

export const colors = colorArr
// [
//   {
//     key: 'red',
//     value: '#C94949'
//   },
//   {
//     key: 'green',
//     value: '#40C47C'
//   },
//   {
//     key: 'yellow',
//     value: '#EFBB00'
//   }
// ]

export const detailColors = colorArr
// [
//   {
//     key: 'red',
//     value: '#FF6B00'
//   },
//   {
//     key: 'green',
//     value: '#53B43B'
//   },
//   {
//     key: 'yellow',
//     value: '#FFB800'
//   }
// ]

export const errorMessage = {
  notMatchOptionsLength: 'The number of ranges cannot be more than choices!',
  loseTokenTitle: 'Your token is lost!',
  loseTokenContent: 'Please sign in again'
}

export const staticTrackingOuraMetric = [
  {
    key: 'origin-Total Sleep Time',
    value: 'origin-Total Sleep Time',
    title: 'Total Sleep Time',
    children: [
      {
        key: 'Sleep Time - Asleep',
        value: 'Sleep Time - Asleep',
        title: 'Sleep Time - Asleep'
      },
      {
        key: 'Sleep Time - In Bed',
        value: 'Sleep Time - In Bed',
        title: 'Sleep Time - In Bed'
      }
    ]
  },
  {
    key: 'origin-Bed Time',
    value: 'origin-Bed Time',
    title: 'Bed Time',
    children: [
      {
        key: 'Bed Time',
        value: 'Bed Time',
        title: 'Bed Time'
      },
      {
        key: 'Wake-up Time',
        value: 'Wake-up Time',
        title: 'Wake-up Time'
      }
    ]
  },
  {
    key: 'origin-Sleep Stages',
    value: 'origin-Sleep Stages',
    title: 'Sleep Stages',
    children: [
      {
        key: 'SleepAwake',
        value: 'SleepAwake',
        title: 'SleepAwake'
      },
      {
        key: 'SleepRem',
        value: 'SleepRem',
        title: 'SleepRem'
      },
      {
        key: 'SleepLight',
        value: 'SleepLight',
        title: 'SleepLight'
      },
      {
        key: 'SleepDeep',
        value: 'SleepDeep',
        title: 'SleepDeep'
      }
    ]
  },
  {
    key: 'origin-Heart Rate',
    value: 'origin-Heart Rate',
    title: 'Heart Rate',
    children: [
      {
        key: 'SleepMaxHeartRate',
        value: 'SleepMaxHeartRate',
        title: 'SleepMaxHeartRate'
      },
      {
        key: 'SleepMinHeartRate',
        value: 'SleepMinHeartRate',
        title: 'SleepMinHeartRate'
      },
      {
        key: 'SleepAvgHeartRate',
        value: 'SleepAvgHeartRate',
        title: 'SleepAvgHeartRate'
      }
    ]
  },
  {
    key: 'origin-Sleep Efficiency',
    value: 'origin-Sleep Efficiency',
    title: 'Sleep Efficiency',
    children: [
      {
        key: 'SleepEfficiency',
        value: 'SleepEfficiency',
        title: 'SleepEfficiency'
      }
    ]
  },
  {
    key: 'origin-Heart Rate Variation',
    value: 'origin-Heart Rate Variation',
    title: 'Heart Rate Variation',
    children: [
      {
        key: 'SleepHeartRateVariation',
        value: 'SleepHeartRateVariation',
        title: 'SleepHeartRateVariation'
      },
      {
        key: 'SleepMaxHeartRateVariation',
        value: 'SleepMaxHeartRateVariation',
        title: 'SleepMaxHeartRateVariation'
      },
      {
        key: 'SleepMinHeartRateVariation',
        value: 'SleepMinHeartRateVariation',
        title: 'SleepMinHeartRateVariation'
      }
    ]
  }
]

export const publicPath = [
  '/ui',
  '/register',
  '/password-reset',
  '/privacy',
  '/permanent_link/',
  '/creport/preview/',
  '/app/share-link/',
  '/ios/share-link/',
  '/share-link/',
  '/sign-in',
  '/terms-of-use',
  '/privacy-policy',
  '/check-in',
  '/facility-waiver',
  '/support',
  '/mobile',
  '/redirect/'
]

export const noCheckPath = [
  '/redirect-sign-waiver-form',
  '/questionnaire/nutrition-score',
  '/membership-selection',
  '/membership-agreement',
  '/onboarding'
]

export const fontFamily =
  'Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

/* cheking before push code */
// const env = process.env.REACT_APP_WORKFLOW

// staging

const sections = [
  {
    name: 'Goals',
    value: 1
  },
  { name: 'Contacts', value: 2 },
  {
    name: 'Medical History',
    value: 3
  },
  {
    name: 'Lifestyle',
    value: 4
  },
  {
    name: 'Physical Activity',
    value: 5
  },
  {
    name: 'Orthopedic',
    value: 6
  },
  {
    name: 'Nutrition & Eating Habits',
    value: 7
  },
  {
    name: 'Sleep',
    value: 8
  }
]

// const stagingQuestionSection = [
//   {
//     name: 'MEDICAL HISTORY',
//     value: 1
//   },
//   { name: 'RESISTANCE TRAINING', value: 2 },
//   {
//     name: 'AEROBIC EXERCISE',
//     value: 3
//   },
//   {
//     name: 'NUTRITION & EATING HABITS',
//     value: 4
//   },
//   {
//     name: 'REJUVENATION',
//     value: 5
//   }
// ]

// const mattermeQuestionSection = [
//   {
//     name: 'GOALS',
//     value: 1
//   },
//   { name: 'MEDICAL HISTORY', value: 2 },
//   {
//     name: 'AEROBIC EXERCISE',
//     value: 3
//   },
//   {
//     name: 'RESISTANCE TRAINING',
//     value: 4
//   },
//   {
//     name: 'ACTIVITY EXERCISE',
//     value: 5
//   },
//   {
//     name: 'NUTRITION & EATING HABITS',
//     value: 6
//   },
//   { name: 'REJUVENATION', value: 7 }
// ]

const questionSection = sections
// env === 'staging' ? stagingQuestionSection : mattermeQuestionSection

export const questionnaireConfig = {
  sections: questionSection.map((item) => item.value),
  nutritionId: 7, //env === 'staging' ? 7 : 6, // on staging is 7
  questionSection,
  surveyId: 4, //env === 'staging' ? 4 : 20, // on staging is 4
  surveyName: 'Client Intake Form 3.0'
  // env === 'staging' ? 'Client Intake Form 3.0' : 'Client Intake Form 4.0'
}

export const healthCardList = [
  {
    name: 'Balance',
    type: 'balance',
    description:
      'Balance disorders are one of the most common causes of falls in older adults. Maintaining good balance from now will help prevent daily difficulties when you’re older: getting in and out of cars, climbing stairs, and taking showers.'
  },
  {
    name: 'Movement Quality',
    type: 'joint_fitness', //'movement_quality',
    description:
      'By maintaining a healthy movement pattern as you age, you’ll be able to perform essential activities of daily living in older years, such as tying your shoes, lifting up your grandkids, and washing your head. Also, good movement quality could lubricate the joints and reduce your risk of arthritis.'
  },
  {
    name: 'Muscle Fitness',
    type: 'muscular_fitness',
    description:
      'Good muscular fitness is essential for staying functional as you age (e.g., being able to climb stairs easily) and resisting sarcopenia (loss of skeletal muscle mass). It also helps prevent bone fractures in the event you fall or get injured. '
  },
  {
    name: 'Body Composition',
    type: 'body_composition',
    description:
      'Your score looks beyond scale weight and considers the three most important factors for health and longevity. Body fat percentage, the percentage of your body weight that consists of fat as measured by DXA. Visceral adipose tissue, a type of body fat linked to higher risk for heart disease and Type 2 diabetes. And appendicular lean mass, which important for strength, function, metabolism, and storage of nutrients.'
    // 'Your score looks beyond your weight to consider your body fat percentage and amount of visceral adipose tissue, a type of body fat linked to higher risk for heart disease and Type 2 diabetes.'
  },
  {
    name: 'Bone Density',
    type: 'bone_health', //'bone_density',
    description:
      'As you age, your bone density is affected by nutrition, exercise, hormonal loss, and even genetics. Your score considers your risk of having a bone fracture, particularly in prone areas such as your back and hip.'
  },
  {
    name: 'Aerobic fitness',
    type: 'aerobic_fitness',
    description:
      'Your score is a measure of your body’s ability to deliver oxygen to your muscle cells and use it to generate energy. While good aerobic fitness is important for all decades of life, it has been associated with better brain and heart health in older age.'
  },
  {
    name: 'Cognitive Health',
    type: 'cognitive_health',
    description:
      'Cognitive health refers to the overall functioning and well being of the brain and its ability to process information, think critically, and make decisions. Factors that can impact cognitive health include genetics, age, lifestyle, and medical conditions. Maintaining cognitive health involves engaging in activities that stimulate the brain (E.g. reading, puzzles, and social interaction) and adopting healthy habits like getting enough sleep, exercising regularly, and managing stress.'
  },
  {
    name: 'Cardiometabolic',
    type: 'cardiometabolic',
    description:
      'Your score emcompasses the health of your heart, blood vessels, and metabolism. It considers your risk for developing heart disease and Type 2 diabetes, two prevalent chronic diseases.'
  }

  // {
  //   name: 'Total Apeiron Life score',
  //   key: 'cardiometabolic',
  //   description: 'We create a composite score with all of your health and fitness data, giving you one number to monitor over time. Like all of your scores, this is adjusted for your age and gender.'
  // }
]

export const muscleMetricsWithBodyChart = [
  'Hip Abduction Total',
  'Hip Adduction Total',
  'Knee Flexion',
  'Knee Extension'
]

export const BookedFor = [
  {label: 'Self', value: 'self'}, 
  {label: 'Spouse', value: 'spouse'}, 
  {label: 'Kid', value: 'kid'}, 
  {label: 'Parent', value: 'parent'}, 
  {label: 'Sibling', value: 'sibling'}, 
  {label: 'Other', value: 'other'}
]

export const transactionReasons = [
  'Appointment Completed',
  'Appointment Cancelled',
  'Membership Purchased',
  'Membership Renewal',
  'Membership Ended',
  'Extra Sessions Purchased',
  'Other'
]

export const AppointmentStatus = [
  { label: 'Completed', value: 'Completed' },
  { label: 'Canceled', value: 'Canceled' },
  { label: 'Not Started', value: 'Not Started' }
]

export const Locations = [
  { label: 'Home', value: 'home' },
  { label: 'ApeironLife', value: 'apeironlife' },
  { label: 'Virtual', value: 'virtual' }
]

export const defaultSessionPricingData = [
  {
    key: '1',
    locations: 'Home',
    sessionsUsed: '1.2',
    clientPay: '200'
  },
  {
    key: '2',
    locations: 'ApeironLife',
    sessionsUsed: '1',
    clientPay: '200'
  },
  {
    key: '3',
    locations: 'Virtual',
    sessionsUsed: '1',
    clientPay: '200'
  },
  {
    key: '4',
    locations: 'Other',
    sessionsUsed: '1',
    clientPay: '200'
  }
]

export const membershipTypes = [
  { value: 'insights', label: 'Healthspan Insights' },
  { value: 'body recomposition', label: 'Body Recomposition' },
  { value: 'evaluation2', label: 'Healthspan Assessment' },
  { value: 'gold', label: 'Gold Membership' },
  { value: 'silver', label: 'Silver Membership' },
  { value: 'bronze', label: 'Bronze Membership' },
  { value: 'remote', label: 'Remote Membership' },
  { value: 'remote lite', label: 'Remote Lite Membership' },
  { value: 'core', label: 'Core A La Carte' },
  { value: 'core+', label: 'Core' },
  { value: 'lite', label: 'Apeiron Lite' },
  { value: 'module', label: 'Module Membership' }
]
