import React, {useState} from 'react'
import styled from 'styled-components'
import { Popover, Divider, Button } from 'antd'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import ManFront from '../comp/painful/manFront'
import ManBack from '../comp/painful/manBack'
import WomanFront from '../comp/painful/womanFront'
import WomanBack from '../comp/painful/womanBack'
import { PopTitle, level, levelColor, PopContent } from '../comp/body'
import HideWrap, { ViewIcon } from '../comp/hideWrap'
import UploadImage from '../comp/uploadImage'
import { RangeBar } from '../comp/bodyComp'
import AsymmetryChart from '../comp/AsymmetryChart'
import { RangeColors } from '../../../utils'
import CervicalLateralFlexionImage from '../../../asserts/images/physical-assessment/ic-cervical-lateral-flexion.svg'
import MultiSegmentalFlexionImage from '../../../asserts/images/physical-assessment/ic-multi-segmental-flexion.png'
import MultiSegmentalFlexionMale from '../../../asserts/images/physical-assessment/MultisegmentalFlexionMale.svg'
import InternalExternalImage from '../../../asserts/images/physical-assessment/ic-internal-external-range.svg'
import InternalExternalImageFemale from '../../../asserts/images/physical-assessment/SierFemale.svg'
import SingleLegSquatImage from '../../../asserts/images/physical-assessment/ic-single-leg-squat.svg'
import OverheadSquatImage from '../../../asserts/images/physical-assessment/ic-overhead-squat.svg'
import OverheadSquatFemale from '../../../asserts/images/physical-assessment/OverheadSquatfemale-cropped.svg'
import { ScoreColorGroup } from '../comp/Widgets'
import ConditionalHideWrap from './ConditionalHidewrap'

const PainfulContainer = styled.div`
  .score {
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    color: #4c6072;
    font-size: 18px;
    width: 70%;
    white-space: nowrap;
    .large-number {
      font-size: 32px;
      font-weight: 600;
    }
    .anticon {
      font-size: 14px;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  .detail {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    padding-right: 60px;
  }
  .media-container {
    display: flex;
    padding-left: 20px;
    padding-bottom: 50px;
    & > div > div,  //preview
    input + div {
      width: 322px;
      height: 430px;
      margin-right: 40px;
      .add-btn {
        display: inline-block;
        border: 1px solid #264382;
        padding: 10px 35px;
        border-radius: 38px;
      }
    }
  }
  .images {
    svg {
      height: auto;
      vertical-align: middle;
    }
  }
  .right-info {
    // padding-top: 70px;
    position: relative;
    text-align: center;
    .classification-title {
      font-size: 18px;
      letter-spacing: 0.3px;
      color: rgba(76, 96, 114, 1);
      font-weight: 600;
    }
    .classification-value {
      font-size: 24px;
      letter-spacing: 0.3px;
      font-weight: 600;
    }
    .sfma-title {
      margin-top: 80px;
      font-size: 15px;
      color: #4c6072;
    }
    .sfma-chart {
      position: absolute;
      width: 270px;
      margin-top: -25px;
    }
    .asymmetry-chart-container {
      margin-top: 48px;
      position: relative;
      height: 50px;
      .polar-chart-name {
        font-size: 15px;
        margin-bottom: -20px;
        color: rgba(76, 96, 114, 1);
      }
      & > div:last-child {
        position: absolute;
        width: 340px;
        transform: translateX(-27%);
        .label {
          margin-top: 0px !important;
          opacity: 1;
        }
      }
    }
  }
  .label {
    margin-top: 40px;
    font-size: 14px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #4c6072;
    opacity: 0.6;
    white-space: nowrap;
  }
  ${level}
  .level {
    cursor: pointer;
  }
`

const painfulList = [
  {
    name: 'Neck',
    key: 'neck'
  },
  {
    name: 'Shoulder rotation',
    key: 'shoulderRotation'
  },
  {
    name: 'Multi segmental',
    key: 'multiSegmental'
  },
  {
    name: 'Legs',
    key: 'legs'
  }
]

export default function MovementQuality(props) {
  const {
    hideSectionHandle,
    hideSection,
    dataList,
    compareData,
    save,
    handleSave,
    loginRole,
    onScoreChangeHandle,
    arDetail: { notes, status, detail },
    view,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    person,
    ageScopes,
    dynamicData: {
      movementQuality: {
        quality: { summary, front, back },
        opacity,
        sfma_total_score,
        cervical_lateral_flexion_asymmetry,
        cervical_lateral_flexion_left,
        cervical_lateral_flexion_right,
        multi_segment_flexion,
        peak_knee_flexion_asymmetry,
        peak_knee_flexion_left,
        peak_knee_flexion_right,
        sier_asymmetry,
        sier_left,
        sier_right,
        single_leg_squat_depth_asymmetry,
        single_leg_squat_depth_left,
        single_leg_squat_depth_right,
        total_limitations,
        total_pain_points
      }
    }
  } = props

  const data = dataList.find((item) => item.name === 'Movement Quality') || {}
  const compare =
    compareData.find((item) => item.name === 'Movement Quality') || {}

  const movementQuality = notes.find(
    (item) => item.pillar === 'movement quality'
  )
  const gender = person && person.profile && person.profile.gender
  // let sfmaRate
  // if (sfma_total_score) {
  //   sfma_total_score.series =
  //     sfma_total_score.series &&
  //     sfma_total_score.series.map((item, index) => {
  //       if (index === sfma_total_score.series.length - 1) {
  //         item.axisColor = '#94A0AA'
  //       } else {
  //         item.axisColor = '#C6CDD2'
  //       }
  //       return item
  //     })

  //   if (
  //     _.isNumber(sfma_total_score.value) &&
  //     _.isNumber(sfma_total_score.previous)
  //   ) {
  //     sfmaRate = Math.round(
  //       ((sfma_total_score.value - sfma_total_score.previous) /
  //         sfma_total_score.previous) *
  //         100
  //     )
  //   }
  // }

  const metrics = [
    {
      name: 'Cervical Lateral Flexion',
      image: CervicalLateralFlexionImage,
      bars: [
        {
          ...cervical_lateral_flexion_right,
          name: 'Cervical Lateral Flexion Right'
        },
        {
          ...cervical_lateral_flexion_left,
          name: 'Cervical Lateral Flexion Left'
        }
      ],
      asymmetry: {
        ...cervical_lateral_flexion_asymmetry
      },
      asymmetryText: 'Cervical Lateral Flexion Asymmetry'
    },
    {
      name: 'Multi Segmental Flexion',
      image: gender === 'male' ? MultiSegmentalFlexionMale : MultiSegmentalFlexionImage,
      imageStyle: {
        width: 180
      },
      bars: [
        {
          ...multi_segment_flexion,
          name: 'Multi Segmental Flexion'
        }
      ]
    },
    {
      name: 'Internal and External Range of Motion',
      image: gender === 'male' ? InternalExternalImage : InternalExternalImageFemale,
      imageStyle: {
        width: 180
      },
      bars: [
        {
          ...sier_right,
          name: 'Shoulder Internal External Range of Motion Right'
        },
        {
          ...sier_left,
          name: 'Shoulder Internal External Range of Motion Left'
        }
      ],
      asymmetry: {
        ...sier_asymmetry
      },
      asymmetryText: 'Shoulder Internal External Range of Motion Asymmetry'
    },
    {
      name: 'Single Leg Squat',
      image: SingleLegSquatImage,
      bars: [
        {
          ...single_leg_squat_depth_right,
          name: 'Single Leg Squat Depth Right '
        },
        {
          ...single_leg_squat_depth_left,
          name: 'Single Leg Squat Depth Left'
        }
      ],
      asymmetry: {
        ...single_leg_squat_depth_asymmetry
      },
      asymmetryText: 'Single Leg Squat Depth Asymmetry'
    },
    {
      name: 'Overhead Squat',
      image: gender === 'male' ? OverheadSquatImage : OverheadSquatFemale,
      imageStyle: {
        width: 180
      },
      bars: [
        {
          ...peak_knee_flexion_right,
          name: 'Peak Knee Flexion Right'
        },
        {
          ...peak_knee_flexion_left,
          name: 'Peak Knee Flexion left'
        }
      ],
      asymmetry: {
        ...peak_knee_flexion_asymmetry
      },
      asymmetryText: 'Peak Knee Flexion Asymmetry'
    }
  ]

  function renderImageGroup() {
    if (
      view !== 'Draft' &&
      (!detail ||
        [
          detail.painful_image_1,
          detail.painful_image_2,
          detail.painful_image_3
        ].filter((item) => item).length === 0)
    ) {
      return null
    }

    return (
      <HideWrap
        name="Movement Quality - images"
        title="images"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <div className="sub-title">Images</div>
        <div className="media-container">
          {(view === 'Draft' || detail.painful_image_1) && (
            <div>
              <UploadImage
                onlyView={view !== 'Draft'}
                personId={person.id}
                url={detail.painful_image_1}
                uploadSync={(url) =>
                  handleSave({
                    detail: {
                      painful_image_1: url
                    }
                  })
                }
              />
            </div>
          )}
          {(view === 'Draft' || detail.painful_image_2) && (
            <div>
              <UploadImage
                // placeholder={Spine}
                onlyView={view !== 'Draft'}
                personId={person.id}
                url={detail.painful_image_2}
                uploadSync={(url) =>
                  handleSave({
                    detail: { painful_image_2: url }
                  })
                }
              />
            </div>
          )}
          {(view === 'Draft' || detail.painful_image_3) && (
            <div>
              <UploadImage
                // placeholder={Spine}
                onlyView={view !== 'Draft'}
                personId={person.id}
                url={detail.painful_image_3}
                uploadSync={(url) =>
                  handleSave({
                    detail: { painful_image_3: url }
                  })
                }
              />
            </div>
          )}
        </div>
      </HideWrap>
    )
  }

  return (
    <>
      <ConditionalHideWrap
        name="Movement Quality"
        isSection={true}
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
        condition={
          view === 'Draft' || (view === 'Preview' && !hideSection.includes('Movement Quality'))
        }
      >
        <section>
          <h1 className="section-title">Movement Quality</h1>
          <div className="sub-title">Summary</div>
          <HealthCard
            {...data}
            compare={compare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={movementQuality ? movementQuality.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'movement quality', status)
            }
            updateEditingCount={updateEditingCount}
          />
          <div
            style={{
              position: 'relative'
            }}
          >
            <ScoreColorGroup
              domain={'movement'}
              style={{
                position: 'absolute',
                right: 0,
                top: 0
              }}
            />
          </div>
          {metrics.map((metric, index) => {
            return (
              <ConditionalHideWrap
                name={metric.name}
                syncStatus={hideSectionHandle}
                status={hideSection}
                view={view}
                isMovementQualityMetrics={true}
                condition={
                  view === 'Draft' || (view === 'Preview' && !hideSection.includes(`${metric.name}`))
                }
              >
                <React.Fragment>
                  {index > 0 && <Divider />}
                  <MovementMetricCard
                    {...metric}
                    person={person}
                    ageScopes={ageScopes}
                  />
                </React.Fragment>
              </ConditionalHideWrap>
            )
          })}

          <PainfulContainer>
            {/* {sfma_total_score && (
              <Row className="score">
                <Col span={8}>
                  <span
                    style={{ color: levelColor[sfma_total_score.color] }}
                    className="large-number"
                  >
                    {sfma_total_score.value || '--'}
                    {sfmaRate > 0 && (
                      <CaretUpOutlined style={{ color: '#40c47c' }} />
                    )}
                    {sfmaRate < 0 && (
                      <CaretDownOutlined style={{ color: '#ff6b00' }} />
                    )}
                  </span>
                  <br />
                  <span>SFMA Total Score</span>
                </Col>
                <Col span={8}>
                  <span
                    style={{ color: levelColor[sfma_total_score.color] }}
                    className="large-number"
                  >
                    {sfma_total_score.level || '--'}
                  </span>
                  <br />
                  <span>Classification</span>
                </Col>
                <Col span={8}>
                  <span className="large-number">
                    {sfma_total_score.previous || '--'}
                  </span>
                  <br />
                  <span>Previous Score</span>
                </Col>
              </Row>
            )} */}

            <div className="detail">
              <div className="images">
                <PainfulImages
                  {...{ ...opacity, person, quality: { front, back } }}
                />
              </div>
              <div className="right-info">
                <div className="classification-title">Classification</div>
                <div
                  style={{ color: levelColor[sfma_total_score.color] }}
                  className="classification-value"
                >
                  {sfma_total_score.level || '--'}
                </div>
                {painfulList.map((item, index) => (
                  <div key={index}>
                    <div className="label">{item.name}</div>
                    <Popover
                      placement="topLeft"
                      overlayClassName="popover-no-arrow"
                      title={
                        <PopTitle>
                          <div>{item.name}</div>
                          <div
                            className="level"
                            style={
                              summary[item.key].color
                                ? {
                                    color: levelColor[summary[item.key].color]
                                  }
                                : {}
                            }
                          >
                            {summary[item.key].level || '--'}
                          </div>
                        </PopTitle>
                      }
                      content={getContent(summary[item.key].metrics)}
                    >
                      <div
                        className="level"
                        style={
                          summary[item.key].color
                            ? {
                                color: levelColor[summary[item.key].color]
                              }
                            : {}
                        }
                      >
                        {summary[item.key].level || '--'}
                      </div>
                    </Popover>
                  </div>
                ))}
                {/* {sfma_total_score && sfma_total_score.series && (
                  <>
                    <div className="sfma-title">
                      SFMA Total Score{' '}
                      {sfmaRate && (
                        <span style={{ marginLeft: 10 }}>
                          <Growth
                            ratio={sfmaRate}
                            up_color="green"
                            // extraStyle={item.extraStyle || {}}
                          />
                        </span>
                      )}
                    </div>
                    <div className="sfma-chart">
                      <BodyChart
                        series={sfma_total_score.series}
                        name="SFMA Total Score"
                        dateFormat="M/D/YY"
                      />
                    </div>
                  </>
                )} */}
                <div className="asymmetry-chart-container">
                  <div className="polar-chart-name">
                    <div>Limitations</div>
                  </div>
                  <AsymmetryChart
                    metric={total_limitations}
                    {...{ ageScopes, person }}
                    isLimitationsAndPainPoints={true}
                  />
                </div>
                <div className="asymmetry-chart-container">
                  <div className="polar-chart-name">
                    <div>Pain Points</div>
                  </div>
                  <AsymmetryChart
                    metric={total_pain_points}
                    {...{ ageScopes, person }}
                    isLimitationsAndPainPoints={true}
                  />
                </div>
              </div>
            </div>
            {renderImageGroup()}
          </PainfulContainer>
        </section>
      </ConditionalHideWrap>
    </>
  )
}

function getContent(metrics) {
  return (
    <PopContent>
      <ul>
        {Object.keys(metrics).map((item, index) => (
          <li key={index}>
            <div>
              {metrics[item].icon ? (
                <img src={metrics[item].icon} alt="icon" className="icon" />
              ) : (
                <span className="icon" />
              )}
              {item}
            </div>
            <div
              className="level"
              style={
                metrics[item] && metrics[item].color
                  ? { color: levelColor[metrics[item].color] }
                  : {}
              }
            >
              {(metrics[item] && metrics[item].level) || '--'}
            </div>
          </li>
        ))}
      </ul>
    </PopContent>
  )
}

function PainfulImages(props) {
  const { front, back, person, quality } = props

  const overlayStyle = { minWidth: 230 }
  const colorMaps = {
    '0': 'transparent',
    '34': '#7FB800',
    // 'radial-gradient(71.76% 71.76% at 49.11% 50.38%, #7FB800 0%, rgba(127, 184, 0, 0.2) 100%)',
    '67': '#FFB800',
    // 'radial-gradient(64.71% 64.48% at 69.12% 50.19%, #FFB800 0%, rgba(255, 184, 0, 0.2) 100%)',
    '100': '#D5001C'
    // 'radial-gradient(64.71% 64.48% at 69.12% 50.19%, rgba(213, 0, 28, 0.9) 0%, rgba(213, 0, 28, 0.2) 100%)'
  }

  return person.profile.gender === 'male' ? (
    <>
      <ManFront
        opacities={front}
        getContent={getContent}
        quality={quality.front}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
      <ManBack
        opacities={back}
        getContent={getContent}
        quality={quality.back}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
    </>
  ) : (
    <>
      <WomanFront
        opacities={front}
        getContent={getContent}
        quality={quality.front}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
      <WomanBack
        opacities={back}
        getContent={getContent}
        quality={quality.back}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
    </>
  )
}

const MetricContainer = styled.div`
  height: ${(props) => props.height || '500px'};
  position: relative;

  .metric-name {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 27px;
    position: absolute;
    top: ${(props) => props.nameMarginTop || '68px'};
    color: #4c6072;
  }
  .metric-content {
    display: flex;
    align-items: center;
    height: 100%;
    & > div {
      display: inline-block;
    }

    .metric-middle-container {
      display: flex;
      flex-direction: column;
    }
  }
  .metric-polar-chart-container {
    position: absolute;
    width: 340px;
    top: 50%;
    right: -75px;
    transform: translateY(-50%);
    .polar-chart-name {
      font-size: 15px;
      line-height: 21px;
      color: #4c6072;
      display: flex;
      text-align: center;
      justify-content: center;
      & > div {
        max-width: 220px;
      }
    }
  }
`

export function MovementMetricCard(props) {
  const { bars, person, ageScopes, name } = props

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px'
        }}
      >
        <div
          style={{
            fontSize: 18,
            fontWeight: 600,
            letterSpacing: 0.3,
            color: '#4c6072'
          }}
        >
          {name}
        </div>
      </div>

        <MetricContainer
          height={props.height}
          nameMarginTop={props.nameMarginTop}
        >
      <div className="metric-content">
        <div
          className="metric-image-conatiner"
          style={{
            width: '22.57%'
          }}
        >
          <img src={props.image} alt="bg" style={props.imageStyle || {}} />
        </div>
        <div
          className="metric-middle-container"
          style={{
            width: '53.57%'
          }}
        >
          {bars.map((bar, index) => {
            return (
              <RangeBar
                key={index}
                {...bar}
                {...{ person, ageScopes }}
                levelStyle={{
                  padding: '5px 13px',
                  color: 'white',
                  backgroundColor: RangeColors[bar.color] || 'transparent',
                  fontWeight: '600',
                  borderRadius: '6px'
                }}
                showRangeArea={true}
                hideName={false}
                hideValueNumber={false}
                showLevelOnNameRight={true}
              />
            )
          })}
        </div>
      </div>
      {props.asymmetry && (
        <div className="metric-polar-chart-container">
          <div className="polar-chart-name">
            <div>{props.asymmetryText}</div>
          </div>
          <AsymmetryChart metric={props.asymmetry} {...{ ageScopes, person }} />
        </div>
      )}
    </MetricContainer>
    </>
  )
}
