import React, { useState, useMemo, useEffect, useRef} from 'react'
import { Table, Tag, Input, Button, Checkbox} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { serviceList } from './index'
import _ from 'lodash'
import ClientActionEditIcon from '../../../asserts/icon/client/icon-client-edit.svg'
import Duplicate from '../../../asserts/icon/client/Duplicate.png'
// import ClientActionDetailIcon from '../../../asserts/icon/client/icon-client-trash.svg'
import moment from 'moment'
import { membershipObj, clientTypes } from '../../../models/client.form'
import { AppointmentStatus, Locations} from '../../../utils/constant'
import './CustomTableFilter.css'

export default function DataTable(props) {
  const {
    edit,
    handleDeleteClient,
    handleDuplicate,
    filters,
    filterTypes,
    // setFilters,
    activeKey,
    onFiltersChange,
    selectedAppointments,
    setSelectedAppointments
  } = props
  const [searchName, setSearchName] = useState('')
  const [searchTrainer, setSearchTrainer] = useState('')

  const clientFilterTypes = useMemo(() => {
    if (filterTypes.length) {
      return clientTypes.filter((item) =>
        filterTypes.find((type) => type === item.value)
      )
    } else {
      return clientTypes
    }
  }, [filterTypes])

  const allDurationsRef = useRef([])

  useEffect(() => {
    if (props.dataSource) {
      const uniqueDurations = _.sortBy(
        _.uniq(props.dataSource.map((item) => item && item.duration))
      ).map((value) => ({
        label: value.toString(),
        value: value
      }))
      if (allDurationsRef.current.length === 0) {
        allDurationsRef.current = uniqueDurations
      }
    }
  }, [props.dataSource])

  const handleSelectAppointment = (id) => {
    let selectedAppointmentsCopy = [...selectedAppointments]
    if(selectedAppointments.includes(id)){
      selectedAppointmentsCopy = selectedAppointmentsCopy.filter((item) => item!==id)
    }else selectedAppointmentsCopy.push(id)
    setSelectedAppointments(selectedAppointmentsCopy)
  }

  const columns = [
    {
      title: '',
      key: 'date',
      dataIndex: 'id',
      render: (id, record) => {
        return (
          <div style={{ width: 10 }}>
            <Checkbox checked={selectedAppointments.includes(id)} onChange={(e) => handleSelectAppointment(id)} />
          </div>
        )
      }
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'start_at',
      render: (startAt, record) => {
        const endAt = moment(startAt).add(record.duration, 'minute')
        return (
          <div style={{ width: 90 }}>
            {moment(startAt).format('MMM DD, YYYY')}
            <br />
            {moment(startAt).format('h:mm A') +
              ' - ' +
              moment(endAt).format('h:mm A')}
          </div>
        )
      }
    },
    {
      title: 'appointment',
      key: 'appointment',
      dataIndex: 'summary',
      filterMultiple: false,
      filteredValue: filters.appointment || [],
      filterDropdown: (filterProps) => (
        <CustomFilterDropdown
          {...filterProps}
          items={AppointmentStatus}
          height={150}
        />
      ),
      onFilter: (value, record) => {
        return value === record.status
      },
      render: (text, record) => (
        <div>
          <div>{text}</div>
          {record.status === 'Completed' && (
            <Tag
              color="default"
              style={{ color: '#474747', background: '#E6E9F2' }}
            >
              Completed
            </Tag>
          )}
          {record.status === 'Canceled' && (
            <Tag
              color="red"
              style={{ color: '#FF0000', background: '#FFE4E4' }}
            >
              Canceled
            </Tag>
          )}
          {record.status === 'Not Started' && (
            <Tag color="red" style={{ color: '#fff', background: '#A5ABB2' }}>
              Not Started
            </Tag>
          )}
        </div>
      )
    },
    {
      title: 'service provider',
      key: 'trainer',
      filteredValue: filters.trainer || [],
      dataIndex: 'service_provider_name',
      filterDropdown: (props) => (
        <SearchFilterDropDown
          {...props}
          searchCallback={(searchText) => setSearchTrainer(searchText)}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        const values = value.split(',')
        const filterValue = record.service_provider_name
        return values.find(
          (_value) =>
            filterValue &&
            filterValue
              .toString()
              .toLowerCase()
              .includes(_value.toLowerCase().trim())
        )
      },
      render: (text) =>
        searchTrainer ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={getSearchWords(searchTrainer)}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        )
    },
    {
      title: 'client',
      key: 'client',
      dataIndex: 'client_name',
      filters: clientFilterTypes,
      filteredValue: filters.client || [],
      filterDropdown: (props) => (
        <CustomFilterDropdown
          {...props}
          items={clientFilterTypes.map((type) => ({
            label: type.text,
            value: type.value
          }))}
          height={300}
        />
      ),
      onFilter: (value, record) => {
        return record.membership && value.includes(record.membership)
      },
      render: (text, record) => (
        <div style={{ position: 'relative', width: 40 }}>
          <div style={{ position: 'absolute', width: 100 }}>
            {searchName ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={getSearchWords(searchName)}
                autoEscape
                textToHighlight={text.toString()}
              />
            ) : (
              text
            )}
            {record.membership && (
              <>
                <br />
                <MemberTag membership={record.membership} />
              </>
            )}
          </div>
        </div>
      )
    },
    {
      title: <span style={{ color: 'transparent' }}>1</span>,
      key: 'client1',
      dataIndex: 'client_name',
      filteredValue: filters.client1 || [],
      filterDropdown: (props) => (
        <SearchFilterDropDown
          {...props}
          searchCallback={(searchText) => setSearchName(searchText)}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
            marginLeft: -25,
            position: 'relative',
            zIndex: 2
          }}
        />
      ),
      onFilter: (value, record) => {
        const values = value.split(',')
        const filterValue = record.client_name
        return values.find(
          (_value) =>
            filterValue &&
            filterValue
              .toString()
              .toLowerCase()
              .includes(_value.toLowerCase().trim())
        )
      },
      render: () => ''
    },
    {
      title: 'service',
      key: 'service',
      dataIndex: 'service',
      filterDropdown: (props) => (
        <CustomFilterDropdown
          {...props}
          items={serviceList.map((service) => ({
            label: service,
            value: service.toLowerCase()
          }))}
          height={300}
        />
      ),
      onFilter: (value, record) => {
        const filterValue = record.service.toLowerCase()
        return value.includes(filterValue)
      },
      filteredValue: filters.service || [],
      render: (text) => <div className="capitalize">{text}</div>
    },
    {
      title: 'duration',
      key: 'duration',
      dataIndex: 'duration',
      filterMultiple: true,
      filteredValue: filters.duration || [],
      filterDropdown: (filterProps) => {
        return (
          <CustomFilterDropdown
            {...filterProps}
            items={allDurationsRef.current} 
            height={150}
          />
        )
      },
      onFilter: (value, record) => {
        return record.duration === value
      }
    },
    {
      title: 'sessions used',
      key: 'sessions_used',
      dataIndex: 'sessions_used'
    },
    {
      title: 'location',
      key: 'location',
      dataIndex: 'location',
      filterMultiple: false,
      filteredValue: filters.location || [],
      filterDropdown: (props) => (
        <CustomFilterDropdown
          {...props}
          items={Locations}
        />
      ),
      onFilter: (value, record) => {
        return record.location && value.includes(record.location.toLowerCase())
      },
      render: (text) => <div className="capitalize">{text}</div>
    },
    {
      title: 'client pay',
      key: 'client_pay',
      dataIndex: 'client_pay'
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <div className="link-group" style={{ width: 115 }}>
            <span>
              <img
                style={{ width: 30 }}
                src={ClientActionEditIcon}
                alt="edit"
                onClick={() => edit(record)}
              />
            </span>
            <span onClick={() => handleDeleteClient(record)}>
              {/* <img
                src={ClientActionDetailIcon}
                alt="detail"
                onClick={() => handleDeleteClient(record)}
              /> */}
              <svg
                width="30"
                height="30"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  opacity="0.1"
                  cx="18"
                  cy="17.9999"
                  rx="18"
                  ry="17.9999"
                  fill="#FF0000"
                />
                <path
                  d="M13.7148 22.4566C13.716 22.8654 13.8668 23.2571 14.1345 23.5462C14.4022 23.8353 14.7649 23.9982 15.1434 23.9994H20.8577C21.2362 23.9982 21.5989 23.8353 21.8666 23.5462C22.1343 23.2571 22.2851 22.8654 22.2863 22.4566V16.2852H13.7148V22.4566Z"
                  fill="#FF0000"
                />
                <path
                  d="M23.25 14.0571H20.25V12.6857C20.25 12.5039 20.171 12.3294 20.0303 12.2008C19.8897 12.0722 19.6989 12 19.5 12H16.5C16.3011 12 16.1103 12.0722 15.9697 12.2008C15.829 12.3294 15.75 12.5039 15.75 12.6857V14.0571H12.75C12.5511 14.0571 12.3603 14.1294 12.2197 14.258C12.079 14.3866 12 14.561 12 14.7429C12 14.9247 12.079 15.0991 12.2197 15.2277C12.3603 15.3563 12.5511 15.4286 12.75 15.4286H23.25C23.4489 15.4286 23.6397 15.3563 23.7803 15.2277C23.921 15.0991 24 14.9247 24 14.7429C24 14.561 23.921 14.3866 23.7803 14.258C23.6397 14.1294 23.4489 14.0571 23.25 14.0571ZM17.25 13.3714H18.75V14.0571H17.25V13.3714Z"
                  fill="#FF0000"
                />
              </svg>
            </span>
            <span>
              <img
                style={{ height: 31, width: 33, verticalAlign: 'top', marginLeft: '6px' }}
                src={Duplicate}
                alt="duplicate"
                onClick={() => handleDuplicate(record)}
              />
            </span>
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={props.dataSource || []}
        bordered
        size="middle"
        pagination={false}
        rowKey={'id'}
        onChange={(agination, filters, sorter, extra) => {
          // console.log('🚀 ~ file: table.js:234 ~ DataTable ~ extra:', extra)
          // console.log('🚀 ~ file: table.js:234 ~ DataTable ~ sorter:', sorter)
          // console.log('🚀 ~ file: table.js:234 ~ DataTable ~ filters:', filters)
          // console.log(
          //   '🚀 ~ file: table.js:234 ~ DataTable ~ agination:',
          //   agination
          // )
          if (extra.action === 'filter') {
            onFiltersChange(filters)
          }
        }}
      />
    </>
  )

  function getSearchWords(text) {
    return text ? text.split(',').map((item) => item.trim()) : []
  }
}

function CustomFilterDropdown({
  items,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  labelKey = 'label',
  valueKey = 'value',
  height = 200
}) {
  const isAllSelected = selectedKeys.length === items.length

  return (
    <div className='table-filter-container' style={{height}}>
      <div className='filter-scrollable-area'>
        <Checkbox
          indeterminate={!isAllSelected && selectedKeys.length > 0}
          checked={isAllSelected}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedKeys(items.map((item) => item[valueKey]))
            } else {
              setSelectedKeys([])
            }
          }}
          className='select-all-option'
        >
          Select All
        </Checkbox>
        {items.map((item) => (
          <div key={item[valueKey]} className='filter-values'>
            <Checkbox
              checked={selectedKeys.includes(item[valueKey])}
              onChange={() => {
                const value = item[valueKey]
                if (selectedKeys.includes(value)) {
                  setSelectedKeys(selectedKeys.filter((key) => key !== value))
                } else {
                  setSelectedKeys([...selectedKeys, value])
                }
              }}
            >
              {item[labelKey]}
            </Checkbox>
          </div>
        ))}
      </div>

      <div className='filter-footer'>
        <Button
          onClick={() => {
            clearFilters()
            setSelectedKeys([])
            confirm()
          }}
          size="small"
          className='reset-button'
          disabled={selectedKeys.length === 0}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirm()
          }}
          size="small"
          className='submit-button'
        >
          OK
        </Button>
      </div>
    </div>
  )
}

export function MemberTag({ membership }) {
  const gradient = {
    gold:
      'linear-gradient(264deg, rgb(249, 212, 35) -70.74%, rgb(248, 149, 0) 90.01%)',
    silver:
      'linear-gradient(226deg, rgb(189, 187, 190) 3.21%, rgb(157, 158, 163) 113.49%)',
    bronze:
      'linear-gradient(299deg, rgb(223, 165, 121) 0%, rgb(162, 96, 78) 70.04%)',
    remote: 'linear-gradient(88deg, #89A8BA 4.45%, #9EB7C6 113.97%)',
    'remote lite': 'linear-gradient(88deg, #9DA5AF 4.45%, #9DA5AF 113.97%)'
  }
  const border = {
    gold: 'rgb(248, 149, 0)',
    silver: 'rgb(157, 158, 163)',
    bronze: 'rgb(162, 96, 78)',
    remote: '#9EB7C6',
    'remote lite': '#9DA5AF'
  }
  return (
    <div
      className="member-tag"
      style={{ borderColor: border[membership] || '#9DA5AF' }}
    >
      <span
        style={{
          backgroundImage: gradient[membership] || gradient['remote lite']
        }}
      >
        {membershipObj[membership]}
      </span>
    </div>
  )
}

export function SearchFilterDropDown(props) {
  const {
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    searchCallback
  } = props

  return (
    <div style={{ padding: 8 }}>
      <Input
        // ref={(node) => {
        //   // this.searchInput = node
        // }}
        placeholder="Search  clients"
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={handleSearch}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={handleSearch}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={handleReset} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  )

  function handleSearch() {
    confirm()
    searchCallback && searchCallback(selectedKeys[0])
  }

  function handleReset() {
    clearFilters()
    searchCallback && searchCallback('')
  }
}
