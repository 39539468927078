import React, { useState } from 'react'
import { Modal, Button, message } from 'antd'
import BasicForm from '../../../components/formV4'
import _ from 'lodash'

export default function AppointmentsEditAll(props) {
    const { cancel, selectedAppointments, appointments, submit } = props
    const [basicForm, setBasicForm] = useState(null)
    const [editor, setEditor] = useState(null)
    const [basicValues, setBasicValues] = useState({})

    const updateBasicForm = (changedFields, allFields) => {
        setBasicValues(allFields)
    }

    const basicInfo = () => {
        let fields = [
            {
                type: 'input',
                label: 'SESSIONS USED',
                dbField: 'sessions_used',
                colSpan: 24
            },
            {
                type: 'input',
                label: 'CLIENT PAY',
                dbField: 'client_pay',
                colSpan: 24
            }
        ]
        return fields
    }

    return (
        <Modal
            open={true}
            onCancel={cancel}
            className="phs-modal client-creation"
            width={460}
            footer={
                <>
                    <Button type="primary" ghost onClick={cancel}>
                        CANCEL
                    </Button>
                    <Button type="primary" onClick={onSubmit}>
                        SAVE
                    </Button>
                </>
            }
        >
            <h1 className="title">
                Edit selected records
            </h1>
            <BasicForm
                wrappedComponentRef={(inst) => setBasicForm(inst)}
                initialValues={editor}
                formData={basicInfo(editor)}
                onChange={updateBasicForm}
            />
        </Modal>
    )

    async function onSubmit() {
        const { client_pay, sessions_used } = basicValues
        try {
            await Promise.all(
                selectedAppointments.map(async (id) => {
                    const appointment = appointments.filter((appointment) => appointment.id === id)[0]
                    const body = {
                        client_pay: client_pay || appointment.client_pay,
                        sessions_used: sessions_used || appointment.sessions_used,
                        client_id: appointment.client_id,
                        service_provider_name: appointment.service_provider_name,
                        service: appointment.service,
                        duration: appointment.duration,
                        location: appointment.location,
                        start_at: appointment.start_at,
                        end_at: appointment.end_at,
                        status: appointment.status,
                        booked_for: appointment.booked_for
                    }
                    await submit(body, id)
                })
            )
            message.success('Updated appointments')
            cancel()
        } catch (err) {
            console.log(err)
            message.error('Failed to update appointments')
        }
    }
}
