import React from 'react'
import styled from 'styled-components'
import { Divider, Button, Table } from 'antd'
import {
  MembershipV3LightChecked,
  MembershipV3DarkChecked
} from '../icons/onboarding'
import background from '../../asserts/images/membership-banner.svg'
import { loadUser } from '../../utils/storage'
import './PricingTable.css'
import { ReactComponent as LightChecked} from '../../asserts/images/LightChecked.svg'

const Container = styled.div`
  .select-btn {
    letter-spacing: 2.8px;
    font-size: 14px;
    margin-top: 16px;
    width: 136px;
  }

  .is-current .select-btn {
    background: #264382 !important;
    color: #ffffff; 
  }

  .tag {
    position: absolute;
    color: #ffffff;
    background: #264382;
    font-size: 12px;
    padding: 5px 6px;
    border-radius: 4px;
    top: -22px;
    right: -5px;
    font-weight: 500;
    transform: scale(0.8);
  }

  .health-info {
    background: url(${background});
    margin-bottom: 30px;
    color: #c0e9ff;
    padding: 35px;
    border-radius: 32px;
    max-height: 400px;
    letter-spacing: 1px;
    .tag {
      color: #264382;
      background: #ffffff;
      top: 32px;
      right: 28px;
    }
    .select-btn {
      background: #ffffff;
      margin-top: 0;
      margin-bottom: 14px;
    }
    .title {
      color #ffffff;
      font-size: 28px;
      line-height: 1;
      font-weight: 700;
      span {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .description {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      color: #fffff;
    }
    ul {
      list-style: none;
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      font-weight: 500;
      color: #ffff;
      li {
        display: flex;
        padding: 5px 0;
        width: 220px;
        .anticon {
          margin-right: 6px;
        }
        &:nth-child(3n) {
          width: 500px;
        }
      }
    }
  }

  
  .tip {
    color: #264382;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-top: 15px;
  }
  .plan-container {
    margin-top: 46px;
    border-radius: 16px;
    background: #ffffff;
    color: #6D7D8C;
    font-family: Heebo;
    .add-service {
      margin-top: -50px;
      display: flex;
      li {
        width: ${(props) => (props.isInsightsUser ? '25%' : '20%')};
        padding: 24px;
        border: 1px solid transparent;
        border-right-color: rgba(0, 0, 0, 0.06);
      }
    }
    .plan-list {
      display: flex;
      li {
        width: ${(props) => (props.isInsightsUser ? '25%' : '20%')};
        padding: 24px;
        padding-top: 36px;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        font-family: 'Heebo';
        .name {
          position: relative;
          color: transparent;
          font-size: 24px;
          font-family: Gilroy;
          font-weight: 700;
          letter-spacing: 1px;
          span {
            -webkit-background-clip: text;
          }
        }
        .price {
          color: #313251;
          font-family: Gilroy;
          font-size: 16px;
          span {
            display: inline-block;
          }
        }
        .includes {
          padding-bottom: 12px;
          letter-spacing: 1px;
          font-weight: 400; 
          text-transform: uppercase;
          transform: scale(.9);
          margin-right: -20px;
          margin-left: -8px;
        }
      }
    }
    .add-items {
      color: #52606C;
      li {
        padding: 12px 0;
        width: 100%;
        border: none;
        display: flex;
        .anticon {
          margin-right: 14px;
        }
        &>div {
          padding-right: 6px;
        }
      }
      ul {
        li {
          padding: 0;
          &:before{
            content: '·';
            margin-right: 5px;
          }
        }
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li.is-current{
        border: 1px solid #264382;
        border-radius: 16px;
        background: #E2F2FF;
      }
    }
    .plan-list li.is-current{
      border-bottom: none;
      border-radius: 16px 16px 0 0;
    }
    .add-service  li.is-current{
      border-top: none;
      border-radius: 0 0 16px 16px;
}
  }
`

const healthSpanList = [
  'Cognitive Health',
  'Balance',
  'Comprehensive Assessment Review',
  'Body Composition',
  'Muscle Fitness',
  'Exercise, Nutrition, Supplement, Sleep recommendations',
  'Bone Health',
  'Cardiovascular Fitness',
  null,
  'Movement Quality',
  'Advanced Blood Panel'
  // '2 complimentary training/nutrition sessions'
]

const planList = [
  {
    name: 'INSIGHTS',
    membership: 'insights',
    background: 'linear-gradient(88deg, #9DA5AF 4.45%, #9DA5AF 113.97%)',
    price: 15000,
    priceInfo: ['$10k', '$5000']
  },
  {
    name: 'BRONZE',
    membership: 'bronze',
    background: 'linear-gradient(299deg, #DFA579 0%, #A2604E 70.04%)',
    price: 25000,
    priceInfo: ['$10k', '$15,000']
  },
  {
    name: 'SILVER',
    membership: 'silver',
    background: 'linear-gradient(226deg, #BDBBBE 3.21%, #9D9EA3 113.49%)',
    price: 35000,
    priceInfo: ['$10k', '$25,000']
  },
  {
    name: 'GOLD',
    membership: 'gold',
    background: 'linear-gradient(264deg, #F9D423 -70.74%, #F89500 90.01%)',
    price: 50000,
    priceInfo: ['$10k', '$40,000']
  }
]

const amountFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})

export default function MemberShip(props) {
  const { current, onChange, selectable, price } = props
  const [initialMembership, setInitialMembership] = React.useState(current)
  const user = loadUser()
  const isInsightsUser = user.profile.membership === 'insights'
  const displayPlanList = isInsightsUser
    ? planList.filter(plan => ['gold', 'silver', 'bronze', 'insights'].includes(plan.membership))
    : planList.filter(plan => ['gold', 'silver', 'bronze', 'remote', 'remote lite'].includes(plan.membership))

  const servicesOffered = [
    {
      key: '1',
      service:
        'Dedicated Chief Health Officer to integrate your medical & wellness teams and drive accountability',
      insights: 'QUARTERLY',
      remote: 'UNLIMITED',
      bronze: 'UNLIMITED',
      silver: 'UNLIMITED',
      gold: 'UNLIMITED'
    },
    {
      key: '2',
      service:
        'Scientific assessment of your 350+ performance, resilience & healthspan predictors',
      insights: true,
      remote: true,
      bronze: true,
      silver: true,
      gold: true
    },
    {
      key: '3',
      service:
        'Expert recommendations to boost performance, resilience & healthspan during a live assessment review',
      insights: true,
      remote: true,
      bronze: true,
      silver: true,
      gold: true
    },
    {
      key: '4',
      service:
        'Daily, self-guided exercise program tailored to your healthspan, performance, and resilience goals & vulnerabilities',
      insights: '1 YEAR',
      remote: '1 YEAR',
      bronze: '1 YEAR',
      silver: '1 YEAR',
      gold: '1 YEAR'
    },
    {
      key: '5',
      service:
        'Check-ins + revisions to your daily performance, resilience and healthspan program',
      insights: 'QUARTELY',
      remote: 'MONTHLY',
      bronze: 'MONTHLY',
      silver: 'MONTHLY',
      gold: 'MONTHLY'
    },
    {
      key: '6',
      service:
        'Up to 3 mini-assessments* to track your progress on performance, resilience & healthspan goals',
      insights: false,
      remote: true,
      bronze: true,
      silver: true,
      gold: true
    },
    {
      key: '7',
      service:
        'Sport-specific conditioning (Skiing, golf, marathons, mountain climbing, etc)',
      insights: false,
      remote: true,
      bronze: true,
      silver: true,
      gold: true
    },
    {
      key: '8',
      service:
        'App: Instant mapping of your wearables data to 7 key predictors of Healthspan',
      insights: true,
      remote: true,
      bronze: true,
      silver: true,
      gold: true
    },
    {
      key: '9',
      service:
        'App: Expert monitoring of your activity & direct messaging with our experts',
      insights: 'MONTHLY',
      remote: 'UNLIMITED',
      bronze: 'UNLIMITED',
      silver: 'UNLIMITED',
      gold: 'UNLIMITED'
    },
    {
      key: '10',
      service: 'Invitations to community challenges & events',
      insights: true,
      remote: true,
      bronze: true,
      silver: true,
      gold: true
    },
    {
      key: '11',
      service:
        'POVs and weekly emails on emerging performance, resilience, and healthspan science themes',
      insights: false,
      remote: false,
      bronze: true,
      silver: true,
      gold: true
    },

    {
      key: '12',
      service: 'Family Member Discount (10%, up to $3K)',
      insights: true,
      remote: true,
      bronze: true,
      silver: true,
      gold: true
    },
    {
      key: '13',
      service: 'Option to add 25 or 50 service session bundles',
      insights: true,
      remote: true,
      bronze: true,
      silver: true,
      gold: true
    },
    {
      key: '14',
      service: 'Continuous Glucose Monitoring',
      insights: false,
      remote: false,
      bronze: false,
      silver: false,
      gold: true
    },
    {
      key: '15',
      service: 'Microbiome Analysis Program',
      insights: false,
      remote: false,
      bronze: false,
      silver: false,
      gold: true
    },
    {
      key: '16',
      service: 'Micronutrient Evaluation',
      insights: false,
      remote: false,
      bronze: false,
      silver: false,
      gold: true
    },
    {
      key: '17',
      service: 'Sleep consultation & brain map',
      insights: false,
      remote: false,
      bronze: false,
      silver: false,
      gold: true
    },
    {
      key: '18',
      service:
        'Joint health and performance recovery module (orthopedic consultations , PRP injections, and coordinated care between specialists)',
      insights: false,
      remote: false,
      bronze: false,
      silver: false,
      gold: true
    },
    {
      key: '19',
      service:
        'Service sessions with our expert providers (delivered at Apeiron Life, virtually, or in your home**)',
      insights: '2',
      remote: false,
      bronze: '25',
      silver: '100',
      gold: '125'
    },
    {
      key: '20',
      service: 'Service sessions Available',
      insights: ['Training', 'Nutrition'],
      remote: [],
      bronze: [
        'Training',
        'Nutrition',
        'Yoga',
        'Pilates',
        'Chiropractic',
        'Acupuncture',
        'Swimming',
        'Mindfulness',
        'Rehab/PT/',
        'Injury Evaluation',
        'Massage'
      ],
      silver: [
        'Training',
        'Nutrition',
        'Yoga',
        'Pilates',
        'Chiropractic',
        'Acupuncture',
        'Swimming',
        'Mindfulness',
        'Rehab/PT/',
        'Injury Evaluation',
        'Massage'
      ],
      gold: [
        'Training',
        'Nutrition',
        'Yoga',
        'Pilates',
        'Chiropractic',
        'Acupuncture',
        'Swimming',
        'Mindfulness',
        'Rehab/PT/',
        'Injury Evaluation',
        'Massage'
      ]
    }
  ]

  const columns = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <span
            style={{
              fontSize: '20px',
              color: '#223064',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            Services Offered
          </span>
        </div>
      ),
      dataIndex: 'service',
      key: 'service',
      width: 350
    },
    ...planList.map((plan) => {
      const getClassNames = (membership) => {
        switch (membership) {
          case 'insights':
            return {
              nameClass: 'insights-text',
              priceClass: 'membership-price-text'
            }
          case 'gold':
            return {
              nameClass: 'gold-text',
              priceClass: 'membership-price-text'
            }
          case 'silver':
            return {
              nameClass: 'silver-text',
              priceClass: 'membership-price-text'
            }
          case 'bronze':
            return {
              nameClass: 'bronze-text',
              priceClass: 'membership-price-text'
            }
          default:
            return {
              nameClass: 'default-text',
              priceClass: 'membership-price-text'
            }
        }
      }

      const { nameClass, priceClass } = getClassNames(plan.membership)

      return {
        title: (
          <div style={{ textAlign: 'center' }}>
            <span className={nameClass}>{plan.name}</span>
            <br />
            <span className={priceClass}>
              <span>{amountFormat.format(getPrice(plan))}</span>{' '}
              {!selectable && plan.membership === current && (
                <div
                  className="tag"
                  style={{
                    marginTop: '80px',
                    marginRight: '68px',
                    marginBottom: '30px'
                  }}
                >
                  CURRENT
                </div>
              )}
              {selectable && (
                <Button
                  type="primary"
                  className={`select-btn ${
                    plan.membership === current ? 'selected-btn' : ''
                  }`}
                  ghost={plan.membership !== current}
                  size="large"
                  onClick={() => selectable && onChange(plan)}
                >
                  {plan.membership === current ? (
                    <span>
                      <GreenChecked /> SELECTED
                    </span>
                  ) : (
                    'SELECT'
                  )}
                </Button>
              )}
            </span>
          </div>
        ),
        dataIndex: plan.name.toLowerCase(),
        key: plan.name,
        width: 200,
        align: 'center',
        className: plan.membership === current ? 'highlighted-column' : '',
        render: (value) => {
          if (Array.isArray(value)) {
            return (
              <ul style={{ textAlign: 'left', textTransform: 'uppercase', verticalAlign: 'top' }}>
                {value.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      listStyleType: item === 'Injury Evaluation' ? 'none' : 'initial'
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )
          }
          if (value === true) {
            return (
              <span style={{ fontSize: '16px', color: '#007BFF' }}>
                <MembershipV3DarkChecked />
              </span>
            )
          }
          if (value === false) {
            return <span></span>
          }
          return value
        }
      }
    })
  ]

  const HealthInfo = ({
    current,
    selectable,
    isInsightsUser,
    onChange,
    healthSpanList
  }) => {
    return (
      <>
        <div
          className={`health-info ${
            current === 'evaluation2' ? 'is-current' : ''
          }`}
          onClick={() => selectable && onChange({ membership: 'evaluation2' })}
        >
          <div className="title">
            {initialMembership === 'evaluation2'
              ? 'Healthspan Assessment'
              : 'All packages include a Comprehensive Healthspan Assessment'} <br/>
            {initialMembership === 'evaluation2' && (
              <span>
                {amountFormat.format(
                  getPrice({ membership: 'evaluation2', price: 10000 })
                )}
              </span>
            )}
          </div>
          {selectable && initialMembership === 'evaluation2' && (
            <Button type="primary" className="select-btn" ghost size="large">
              {current === 'evaluation2' ? (
                <span>
                  <GreenChecked /> SELECTED
                </span>
              ) : (
                'SELECT'
              )}
            </Button>
          )}
          {!selectable && current === 'evaluation2' && (
            <div className="tag">CURRENT</div>
          )}
          <div className="description">
            Make the invisible factors impacting your performance, resilience
            and
            <br /> healthspan visible with scientific testing of 350+ markers
          </div>

          <ul>
            {healthSpanList.map((item, index) => (
              <li key={`health-${index}`}>
                {item !== null && <LightChecked width={20} height={18} style={{ marginRight: '8px' }} />}
                <div>{item}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="tip-text">
          <div>
            *Mini-assessments are shorter assessments focused on tracking your
            progress toward your goals
          </div>
          <div>
            **In-home sessions are counted as 1.2 sessions to account for travel
            time and expense
          </div>
        </div>
      </>
    )
  }

  return (
    <Container isInsightsUser={isInsightsUser}>
      {initialMembership === 'evaluation2' && (
        <HealthInfo
          current={current}
          selectable={selectable}
          isInsightsUser={isInsightsUser}
          onChange={onChange}
          healthSpanList={healthSpanList}
        />
      )}
      <div
        className='table-wrapper'
        style={{
          marginBottom: '60px'
        }}
      >
        <Table
          columns={columns}
          dataSource={servicesOffered}
          pagination={false}
          bordered
        />
      </div>
      {initialMembership !== 'evaluation2' && (
        <HealthInfo
          current={current}
          selectable={selectable}
          isInsightsUser={isInsightsUser}
          onChange={onChange}
          healthSpanList={healthSpanList}
        />
      )}
      <div className="contact-email">
        {' '}
        <span className="contact-email-text">Questions? Contact</span>
        <a href="mailto:membership@apeiron.life">membership@apeiron.life</a>
      </div>
    </Container>
  )

  function getPrice(item) {
    const targetPrice = (price || []).find(
      (p) => p.membership === item.membership
    )

    return targetPrice
      ? targetPrice.discountedPrice || targetPrice.price
      : item.price
  }
}

function GreenChecked(props) {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.0625 4.4375L3.25 6.625L8.9375 0.9375"
        stroke="#05B800"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
