import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import BannerBG from '../../../asserts/images/ass-report-bg.png'
import GenericPdfDownloader from './GenericPdfDownloader'
import { loadUser } from '../../../utils/storage'

const BannerContainer = styled.div`
  background: url(${BannerBG}) no-repeat;
  height: 433px;
  background-size: 100%;
  padding: 105px 200px;
  position: relative;
  .download-pdf{
    position: absolute; 
    right: 20px;
    top: 15px;
    border: 1px solid #ffffff;
  }
  .avatar {
    width: 104px;
    height: 104px;
    background: #f1f3f6;
    margin-bottom: 20px;
    border-radius: 104px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .report-title {
    color: #f8fafa;
    font-size: 56px;
    letter-spacing: 0.3px;
    line-height: 120%;
    margin-bottom: 10px;
  }
  .title {
    color: #f8fafa;
    opacity: 0.6;
    font-size: 24px;
  }
`

export default function Banner(props) {
  const { person, period, showPDF, id } = props
  const isAdmin = loadUser().role === 16

  const queryParams = new URLSearchParams(window.location.search)
  const uploadRequest = queryParams.get('savePDF') === 'true'
  
  return (
    <section id={id} style={{ margin: 0, padding: 0 }}>
    <BannerContainer>
      <div className="avatar" id="avatar">
        {person.avatar && <img src={person.avatar.oss_url} alt="avatar" />}
      </div>
      <h1 className="report-title">
        {(person.profile && person.profile.nickname) || person.first_name}'s
        Comprehensive Report
      </h1>
      <h3 className="title">{moment(period).format('MMM YYYY')}</h3>
      {showPDF && isAdmin && <GenericPdfDownloader uploadRequest={uploadRequest} ids={'PDFAssessment'} className='download-pdf' person={person} filename={`${(person.profile && person.profile.nickname) || person.first_name}-report.pdf`} />}
    </BannerContainer>
    </section>
  )
}
