import _ from 'lodash'
import moment from 'moment'
import MarkdownIt from 'markdown-it'
import React, { Component, useState, useEffect } from 'react'
import { Input, DatePicker, Checkbox, Button, Select } from 'antd'
import Loading from '../UI/status/loading'
import Activities from './comp/timelineActivities'
import Notes from './comp/timelineNotes'
import {
  getAll,
  createItem,
  updateItem,
  deleteItem,
  getActivities,
  markAsDone,
  markAsUnDone,
  saveNote
} from '../../api/timeline'
import { uploadToGetStaticUrl } from '../../api/file'
import DataTable from '../UI/table'
import { DelIcon, CalendarIcon, NotesIcon, BlankLinkIcon } from '../icons'
import { timelineStyle } from './timeline.sass'
import { loadUser } from '../../utils/storage'
import {
  getClientDetail,
  getClientList,
  getTimeline
} from '../../models/breadcrumb.model'
import { membershipTypes } from '../../utils/constant'

class TimeLine extends Component {
  state = {
    allData: [],
    versions: [],
    showIndex: 0,
    activeItem: null,
    showNotes: false,
    version: null,
    loading: false,
    onChanging: false
  }

  initial = async (version, refresh) => {
    let person
    if (this.props.person) {
      person = this.props.person
    } else {
      person = await this.props.getPerson()
    }

    const { setClientPageTitle, setClientBreadcrumb } = this.props
    setClientPageTitle('Timeline')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getTimeline(person, true)
      ])

    let { timelines, versions } = await getAll(person.id, version)
    versions = versions.reverse()
    this.setState({
      allData: timelines,
      versions,
      version: version || versions[0],
      person
    })
    if (!this.state.activeItem || refresh) {
      const activeItem = timelines.find((data) => !data.is_done) || timelines[0]
      this.changeActiveItem(activeItem)
    }
  }

  addItem = (id) => {
    const { allData } = this.state
    const target = allData.find((data) => data.id === id)
    target.items.push({
      id: Math.random() / 10,
      name: null,
      planned_date: null,
      completed_date: null
    })
    this.setState({ allData })
  }

  saveItem = async (id, params) => {
    const { version } = this.state
    const { person } = this.state
    params.timeline_id = id
    params.client_id = person.id
    const { allData } = this.state
    const target = allData.find((data) => data.id === id)
    let targetItem = target.items.find((item) => item.id === params.id)
    const targetIndex = target.items.findIndex((item) => item.id === params.id)

    if (params.id >= 1) {
      params = _.assign(_.cloneDeep(targetItem), params)
      await updateItem(params)
    } else {
      delete params.id
      params.version = version
      const newItem = await createItem(params)
      params.id = newItem.id
    }
    target.items.splice(targetIndex, 1, params)
    this.changeActiveItem(target)
  }

  deleteItem = async (itemId, id) => {
    const { allData } = this.state
    const target = allData.find((data) => data.id === id)
    if (itemId >= 1) {
      await deleteItem(itemId)
    }
    target.items = target.items.filter((item) => item.id !== itemId)
    this.changeActiveItem(target)
  }

  markTimelineDone = async () => {
    const { activeItem, version } = this.state
    const { person } = this.state
    await markAsDone({
      client_id: person.id,
      timeline_id: activeItem.id,
      version
    })
    activeItem.is_done = true
    this.initial(version)
  }

  markTimelineUnDone = async () => {
    const { activeItem, version, person } = this.state
    await markAsUnDone({
      client_id: person.id,
      timeline_id: activeItem.id,
      version
    })
    activeItem.is_done = false
    // this.setState({ activeItem: _.cloneDeep(activeItem) })
    this.initial(version)
  }

  saveNote = async (note, file) => {
    const { person } = this.state
    const { activeItem, activities } = this.state
    const creator = loadUser()
    if (file) {
      activities.unshift({
        action: 'Note',
        created_at: new Date(),
        creator,
        note: {
          note,
          created_at: new Date(),
          creator
        }
      })
      this.setState({ activities })
      try {
        const { url } = await uploadToGetStaticUrl({
          personId: person.id,
          file
        })
        const params = _.clone(note)
        params.url = url
        delete params.loading
        const result = await saveNote({
          note: params,
          timeline_id: activeItem.id,
          client_id: person.id
        })
        activities[0].note = result
        this.setState({ activities })
      } catch (err) {
        note.error = err.message
      }
    } else {
      await saveNote({
        note,
        timeline_id: activeItem.id,
        client_id: person.id
      })
      this.changeActiveItem(activeItem)
    }
  }

  changeActiveItem = async (item) => {
    if (!item) return
    const { person } = this.state
    const { allData, version, onChanging } = this.state
    if (onChanging) return
    this.setState({ onChanging: true })
    const targetIndex = item && allData.findIndex((data) => data.id === item.id)
    let prevItem
    if (targetIndex > 0) {
      prevItem = allData[targetIndex - 1]
    }
    this.setState({ activeItem: allData[targetIndex], prevItem })
    const activeItem = await getActivities(item.id, person.id, version)
    const { activities, notes } = activeItem
    allData[targetIndex] = activeItem
    this.setState({ activities, notes, activeItem, onChanging: false })
  }

  checkIfTimelineCanBeMarkAsDone = () => {
    const { activeItem } = this.state
    let disabled = false
    if (
      activeItem &&
      (activeItem.name === 'Renewal' || activeItem.name === 'Renew')
    ) {
      disabled =
        activeItem.is_done ||
        !activeItem.items.find((item) => item.completed_date)
    } else {
      disabled = activeItem && activeItem.is_done
    }

    return disabled
  }

  componentDidMount() {
    this.initial()
  }

  renderBody(data, disableMarkAsDone, isRenewal) {
    const { prevItem, activeItem } = this.state
    const { item_type, items, id, is_done } = data
    const childProps = {
      items,
      disableMarkAsDone,
      isRenewal,
      timelineName: activeItem.name,
      type: item_type,
      isDone: is_done,
      saveItem: (params) => this.saveItem(id, params),
      addItem: () => this.addItem(id, item_type),
      deleteItem: (itemId) => this.deleteItem(itemId, id),
      isPrevDone: !prevItem || prevItem.is_done,
      person: this.state.person
    }
    switch (item_type) {
      case 'text':
        return <TextItems {...childProps} />
      default:
        return <CommonItems {...childProps} />
    }
  }

  render() {
    const {
      allData,
      activeItem,
      activities,
      showNotes,
      versions,
      version
    } = this.state

    let disableMarkAsDone = this.checkIfTimelineCanBeMarkAsDone()

    const isRenewal =
      activeItem &&
      (activeItem.name === 'Renewal' || activeItem.name === 'Renew')

    return (
      <>
        <div style={{ display: 'flex' }}>
          {versions.map((v) => (
            <div
              key={v}
              onClick={() => {
                this.setState({ allData: [], activities: null })
                this.initial(v, true)
              }}
              style={{
                borderRadius: 12,
                cursor: 'pointer',
                background:
                  version === v ? 'rgba(38,67,130,.1)' : 'transparent',
                padding: '7px 22px',
                color: version === v ? '#264382' : '#9DAABA'
              }}
            >
              Year {v}
            </div>
          ))}
        </div>
        {allData.length > 0 ? (
          <div className={timelineStyle}>
            <div className="left-menu">
              <ul>
                {allData.map((item, index) => (
                  <li
                    key={index}
                    className={
                      activeItem && item.id === activeItem.id ? 'active' : ''
                    }
                    onClick={() => {
                      this.changeActiveItem(item)
                    }}
                  >
                    {item.is_done ? <DoneCheck /> : <span>{index + 1}</span>}{' '}
                    <div>
                      {item.name}
                      <div className="date">
                        {item.planOrCompletedDate
                          ? moment(
                              item.planOrCompletedDate.substr(0, 10)
                            ).format('MMM DD, YYYY')
                          : ''}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="right-container">
              {activeItem && (
                <>
                  <div className="title">
                    {activeItem.last_updated && (
                      <span>
                        Last updated:{' '}
                        {moment(activeItem.last_updated).format(
                          'MMM DD, YYYY h:mm a'
                        )}
                      </span>
                    )}
                    <h3>{activeItem.name}</h3>
                    <div className="description">{activeItem.description}</div>
                  </div>
                  <div className="body">
                    {this.renderBody(activeItem, disableMarkAsDone, isRenewal)}
                    <div className="opr">
                      <span
                        className="notes-icon"
                        onClick={() => this.setState({ showNotes: true })}
                      >
                        <NotesIcon />
                      </span>

                      {isRenewal || !disableMarkAsDone ? (
                        <Button
                          type="primary"
                          onClick={this.markTimelineDone}
                          disabled={isRenewal && disableMarkAsDone}
                        >
                          Mark as done
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={this.markTimelineUnDone}
                        >
                          Mark as undone
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <Loading />
        )}
        {activities && (
          <Activities
            list={activities}
            title="Activities"
            timelineName={activeItem && activeItem.name}
          />
        )}
        {showNotes && activities && (
          <Notes
            close={() => {
              this.setState({ showNotes: false })
            }}
            notes={activities.filter((activity) => activity.action === 'Note')}
            saveNote={this.saveNote}
          />
        )}
      </>
    )
  }
}

export default TimeLine

function InputType(props) {
  const [showInput, setShowInput] = useState(!props.value && !props.isDone)

  const onKeyUp = async (e) => {
    if (e.keyCode === 13) {
      const newValue = e.target.value
      await props.save(newValue)
      setShowInput(false)
    }
  }
  const onBlur = async (e) => {
    const newValue = e.target.value
    if (newValue) {
      await props.save(newValue)
      setShowInput(false)
    }
  }
  return showInput ? (
    <Input
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      // disabled={props.isDone}
      autoFocus
      defaultValue={props.value}
    />
  ) : props.showCheckBox ? (
    <Checkbox
      checked={!!props.completedDate}
      disabled={props.isDone}
      onChange={(e) => !props.isDone && props.onCheckBoxChange(e, props.id)}
    >
      <MarkDownContent
        value={props.value}
        onClick={() => !props.isDone && setShowInput(true)}
      />
    </Checkbox>
  ) : (
    <MarkDownContent
      value={props.value}
      onClick={() => !props.isDone && setShowInput(true)}
    />
  )
}

function MarkDownContent(props) {
  const md = new MarkdownIt('zero').enable(['link', 'autolink'])
  let result = md.render(props.value || '')
  const match = result.match(/"[a-zA-z]+:\/\/[^\s]*"/)
  const url = match && match[0].replace(/"/g, '')

  result = result
    /* eslint-disable-next-line no-script-url */
    .replace(/"[a-zA-z]+:\/\/[^\s]*"/g, 'javascript:void(0)')
    /* eslint-disable-next-line no-script-url */
    .replace('href=""', 'href="javascript:void(0)"')

  return (
    <>
      <div
        // style={{ display: 'inline-block' }}
        className="html-container"
        onClick={props.onClick}
        dangerouslySetInnerHTML={{
          __html: result
        }}
      />
      {url && <BlankLinkIcon onClick={() => window.open(url)} />}
    </>
  )
}

function SelectDateType(props) {
  const [showType, setShowType] = useState(!props.value)
  const onChange = async (date, dateString) => {
    await props.save(dateString)
    if (dateString) {
      setShowType(false)
    }
  }
  useEffect(() => {
    setShowType(!props.value)
  }, [props.value])

  return showType ? (
    <DatePicker
      disabled={props.isDone}
      onChange={onChange}
      defaultValue={props.value ? moment(props.value.substr(0, 10)) : null}
    />
  ) : (
    <div
      onClick={() => !props.isDone && setShowType(true)}
      style={{ minHeight: 32, lineHeight: '32px' }}
    >
      <CalendarIcon />
      {'  '}
      {props.value
        ? moment(props.value.substr(0, 10)).format('MMM DD, YYYY')
        : 'Select Date'}
    </div>
  )
}

function CommonItems(props) {
  const {
    items,
    addItem,
    saveItem,
    deleteItem,
    isPrevDone,
    isDone,
    isRenewal,
    type,
    person
    // timelineName
  } = props

  const showCheckBox = type === 'checkbox'

  const onCheckBoxChange = (e, id) => {
    const isChecked = e.target.checked
    if (isChecked) {
      // set completed date
      saveItem({ id, completed_date: moment().format('YYYY-MM-DD') })
    } else {
      // delete completed date
      saveItem({ id, completed_date: null })
    }
  }
  const commonItems = items.filter((item) => item.type !== 'dropdown')

  const columns = [
    {
      title: 'Subcomponents',
      key: 'name',
      dataIndex: 'name',

      render: (text, record, index) => (
        <>
          <InputType
            value={record.name}
            isPrevDone={isPrevDone}
            isDone={isRenewal && isDone}
            showCheckBox={record.type ? record.type === 'checkbox' : showCheckBox}
            completedDate={record.completed_date}
            plannedDate={record.planned_date}
            onCheckBoxChange={(e) => onCheckBoxChange(e, record.id)}
            save={(value) =>
              saveItem({
                name: value,
                id: record.id
              })
            }
          />
          {(record.name === 'Renew' && (record.value || !isDone)) ? (
            <Select
              disabled={isDone}
              style={{ width: 185 }}
              defaultValue={record.value ? record.value.membershipType : (person.profile.membership || '')}
              options={membershipTypes}
              onChange={(value) =>
                saveItem({
                  value: { membershipType: value },
                  id: record.id
                })
              }
            />
          ) : (<></>)}
        </>
      )
    },
    {
      title: 'Planned Date',
      key: 'planned_date',
      dataIndex: 'planned_date',
      width: 150,
      render: (text, record, index) => (
        <SelectDateType
          value={record.planned_date}
          isDone={isRenewal && isDone}
          save={(value) =>
            saveItem({
              planned_date: value,
              id: record.id
            })
          }
        />
      )
    },
    {
      title: 'Completed Date',
      key: 'completed_date',
      dataIndex: 'completed_date',
      width: 150,
      render: (text, record, index) => (
        <SelectDateType
          isDone={isRenewal && isDone}
          value={record.completed_date}
          save={(value) =>
            saveItem({
              completed_date: value,
              id: record.id
            })
          }
        />
      )
    },
    {
      key: 'delete',
      width: 25,
      render: (text, record, index) =>
        !(isRenewal && isDone) && (
          <DelIcon onClick={() => deleteItem(record.id)} />
        )
    }
  ]

  if (type === 'no_plan') {
    columns.splice(1, 1)
  }
  return (
    <div className="common-items">
      <DataTable columns={columns} dataSource={commonItems} hidePagination={true} />
      {!(isRenewal && isDone) && (
        <span className="add-item" onClick={addItem}>
          + Add an item
        </span>
      )}
    </div>
  )
}

function TextItems(props) {
  const { items, addItem, saveItem, deleteItem, isDone, isRenewal } = props
  const columns = [
    {
      title: 'Subcomponents',
      key: 'name',
      dataIndex: 'name',
      render: (text, record, index) => (
        <InputType
          value={record.name}
          isDone={isRenewal && isDone}
          save={(value) =>
            saveItem({
              name: value,
              id: record.id
            })
          }
        />
      )
    },
    {
      key: 'delete',
      width: 25,
      render: (text, record, index) =>
        isDone ? null : <DelIcon onClick={() => deleteItem(record.id)} />
    }
  ]
  return (
    <div className="common-items">
      <DataTable columns={columns} dataSource={items} hidePagination={true} />
      {!isDone && (
        <span className="add-item" onClick={addItem}>
          + Add an item
        </span>
      )}
    </div>
  )
}

function DoneCheck() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#7BAD2D" />
      <path
        d="M10.176 14.3123L7.80466 11.8256L7 12.6694L10.176 16L17 8.84383L16.1953 8L10.176 14.3123Z"
        fill="white"
      />
    </svg>
  )
}
