import React, { useState, useEffect } from 'react'
import {
  getClientList,
  getClientDetail,
  getTransaction
} from '../../../../models/breadcrumb.model'
import './Transaction.css'
import { loadUser, getRole } from '../../../../utils'
import {
  getAppointmentsTransactionsList,
  updateTransaction,
  createTransaction,
  deleteTransaction
} from '../../../../api/appointments'
import { Button, message, Modal } from 'antd'
import SessionSummary from './SessionSummary'
import TransactionsTable from './TransactionsTable'
import FilterPanel from './TransactionsFilterPanel'
import ClientActionEditIcon from '../../../../asserts/icon/client/icon-client-edit.svg'
import DeleteIcon from '../../../../asserts/icon/weekly-summary/delete.svg'
import TransactionModal from './TransactionModal'
import { transactionReasons } from '../../../../utils/constant'
import { editClient } from '../../../../api'
import moment from 'moment'
export default function TransactionsPage(props) {
  const [person, setPerson] = useState(null)
  const [transactionList, setTransactionList] = useState([])
  const [loading, setLoading] = useState(false)
  const [sessionsPurchased, setSessionsPurchased] = useState(0)
  const [sessionsUsed, setSessionsUsed] = useState(0)
  const [activeFilter, setActiveFilter] = useState('date_range')
  const [transactionType, setTransactionType] = useState(null)
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: new Date()
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [modalData, setModalData] = useState({
    transactionType: '',
    transactionDate: new Date(),
    sessions: null,
    amount: null,
    reason: '',
    notes: null
  })
  const [isFlagChecked, setIsFlagChecked] = useState(false)
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false)
  const [isDeleteConfirmModalVisible, setIsDeleteConfirmModalVisible] = useState(false)
  const [selectedTransactonId, setSelectedTransactonId] = useState(false)
  const [pendingCheck, setPendingCheck] = useState(false)

  const userId = loadUser().id
  const loginRole = getRole()

  const formatDate = (date) => {
    return date ? new Date(date).toISOString().split('T')[0] : null
  }

  const fetchMembershipStartDate = async () => {
    try {
      const clientId = loginRole === 'User' ? userId : person.id

      if (!clientId) {
        throw new Error('Client ID is required')
      }

      const params = {
        client_id: clientId,
        session_balance: true
      }

      const response = await getAppointmentsTransactionsList(params)
      const { current_membership_start_date } = response

      setDateRange((prev) => ({
        ...prev,
        startDate: current_membership_start_date
      }))

      return current_membership_start_date
    } catch (error) {
      console.error('Failed to fetch membership start date:', error.message)
      return null
    }
  }

  const fetchTransactions = async (filters = {}) => {
    setLoading(true)
    try {
      const clientId = loginRole === 'User' ? userId : person.id

      if (!clientId) {
        throw new Error('Client ID is required')
      }

      const params = {
        client_id: clientId,
        session_balance: true
      }

      // Only include date range if they are set
      if (filters.start_date || filters.end_date) {
        params.start_date = formatDate(filters.start_date || dateRange.startDate)
        params.end_date = formatDate(filters.end_date || dateRange.endDate)
      }

      // Include other filters if provided (e.g., transaction_type)
      if (filters.transaction_type) {
        params.transaction_type = filters.transaction_type
      }

      const response = await getAppointmentsTransactionsList(params)
      const { transactions, sessions_purchased, sessions_used } = response

      const mappedData = transactions.map((item) => ({
        transaction_id: item.id,
        transactionDate: item.transaction_date,
        transaction_type: item.transaction_type,
        sessions: item.sessions,
        reason: item.reason,
        amount: item.amount,
        notes: item.notes
      }))

      setTransactionList(mappedData)
      setSessionsPurchased(sessions_purchased)
      setSessionsUsed(sessions_used)
    } catch (error) {
      console.error('Failed to fetch transactions:', error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const initialize = async () => {
      if (person || loginRole === 'User') {
        const startDate = await fetchMembershipStartDate()

        if (startDate) {
          fetchTransactions({
            start_date: startDate,
            end_date: dateRange.endDate
          })
        }
      }
    }

    initialize()
  }, [person, loginRole])

  const handleFilterChange = (type) => {
    if (activeFilter === type) {
      setActiveFilter('show_all')
      setTransactionType(null)
      fetchTransactions({
        start_date: dateRange.startDate,
        end_date: dateRange.endDate
      })
    } else {
      setActiveFilter(type)
      setTransactionType(type)
      fetchTransactions({
        transaction_type: type,
        start_date: dateRange.startDate,
        end_date: dateRange.endDate
      })
    }
  }

  const handleDateRangeChange = ({ start_date, end_date }) => {
    setActiveFilter(transactionType ? transactionType : 'date_range')
    setDateRange({
      startDate: start_date,
      endDate: end_date
    })
    fetchTransactions({
      start_date,
      end_date,
      transaction_type: transactionType
    })
  }

  const handleShowAll = () => {
    setActiveFilter('show_all')
    setTransactionType(null)
    setDateRange({ startDate: null, endDate: null })
    fetchTransactions()
  }

  const handleAddTransaction = () => {
    setIsEditMode(false)
    setModalData({
      transactionType: '',
      transactionDate: new Date(),
      sessions: null,
      amount: null,
      reason: '',
      notes: null
    })
    setIsModalVisible(true)
  }

  const handleEditTransaction = (record) => {
    setIsEditMode(true)
    setModalData({
      transactionId: record.transaction_id,
      transactionType: record.transaction_type,
      transactionDate: record.transactionDate,
      sessions: record.sessions,
      reason: record.reason,
      amount: record.amount,
      notes: record.notes
    })
    setIsModalVisible(true)
  }

  const handleDeleteTransaction = (record) => {
    setSelectedTransactonId(record.transaction_id)
    setIsDeleteConfirmModalVisible(true)
  }

  const handleDeleteTransactionConfirm = async () => {
    try {
      await deleteTransaction(selectedTransactonId)
      const transactions = transactionList.filter((transaction) => transaction.transaction_id !== selectedTransactonId)
      setTransactionList(transactions)
      setIsDeleteConfirmModalVisible(false)
      message.success('Delete Transaction successful.')
    } catch (e) {
      message.error('Could not delete transaction. Please try again.')
      console.log('Delete transaction error:', e)
    }
  }

  const handleModalSubmit = async () => {

    if (modalData.sessions < 0) {
      message.error('Sessions should not be Negative')
      return
    }

    if (modalData.sessions === null) {
      message.error('Sessions should not be Empty')
      return
    }

    if (modalData.amount === null) {
      message.error('Amount should not be Empty')
      return
    }

    if (modalData.amount < 0) {
      message.error('Amount should not be Negative')
      return
    }

    if (modalData.reason === '') {
      message.error('Reason should not be Empty')
      return
    }

    try {
      if (isEditMode) {
        const updatedData = {
          transaction_date: modalData.transactionDate,
          client_id: person.id,
          transaction_type: modalData.transactionType,
          sessions: Number(modalData.sessions),
          updated_id: userId,
          amount: modalData.amount || 0,
          reason: modalData.reason,
          notes: modalData.notes ? modalData.notes : null
        }

        await updateTransaction(modalData.transactionId, updatedData)
      } else {
        const newTransactionData = {
          transaction_date: modalData.transactionDate || new Date(),
          client_id: person.id,
          transaction_type: modalData.transactionType,
          sessions: Number(modalData.sessions),
          created_id: userId,
          appointment_id: modalData.appointmentId || null,
          reason: modalData.reason,
          amount: modalData.amount || 0,
          notes: modalData.notes ? modalData.notes : null
        }

        await createTransaction(newTransactionData)
      }

      setIsModalVisible(false)
      fetchTransactions()
    } catch (error) {
      console.error('Error updating/creating transaction:', error)
    }
  }

  const initial = async () => {
    const { setClientPageTitle, setClientBreadcrumb, getPerson } = props
    const personData = getPerson ? await getPerson() : loadUser()
    if (setClientPageTitle && setClientBreadcrumb) {
      setClientPageTitle('Service Sessions')
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(personData),
        getTransaction(personData, true)
      ])
    }
    setPerson(personData)

    if (personData && personData.profile && personData.profile.flags) {
      setIsFlagChecked(personData.profile.flags.showTransactionScreen || false)
    } else {
      setIsFlagChecked(false)
    }
  }

  useEffect(() => {
    initial()
  }, [])

  const columns = [
    {
      title: <span className="title-header-text">DATE</span>,
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      render: (text) => moment(text).format('MMM DD, YYYY')
    },
    {
      title: <span className="title-header-text">TRANSACTION TYPE</span>,
      dataIndex: 'transaction_type',
      key: 'transaction_type',
      render: (type) => (
        <Button
          className={`${type === 'credit' ? 'credit-value' : 'debit-value'}`}
          disabled
        >
          {type.toUpperCase()}
        </Button>
      )
    },
    {
      title: <span className="title-header-text">SESSIONS</span>,
      dataIndex: 'sessions',
      key: 'sessions'
    },
    ...(loginRole === 'Expert' || loginRole === 'InternalAdmin'
      ? [
        {
          title: <span className="title-header-text">AMOUNT</span>,
          dataIndex: 'amount',
          key: 'amount'
        }
      ]
      : []),
    {
      title: <span className="title-header-text">REASON</span>,
      dataIndex: 'reason',
      key: 'reason'
    },
    {
      title: <span className="title-header-text">NOTES</span>,
      dataIndex: 'notes',
      key: 'notes'
    },
    ...(loginRole === 'Expert' || loginRole === 'InternalAdmin'
      ? [
        {
          title: <span className="title-header-text">ACTIONS</span>,
          key: 'actions',
          render: (_, record) => (
            <div className="actions-container">
              <div>
                <img
                  style={{ width: 36, cursor: 'pointer' }}
                  src={ClientActionEditIcon}
                  alt="edit"
                  onClick={() => handleEditTransaction(record)}
                />
              </div>
              <div>
                <img
                  style={{ width: 36, cursor: 'pointer' }}
                  src={DeleteIcon}
                  alt="delete"
                  onClick={() => handleDeleteTransaction(record)}
                />
              </div>
            </div>
          )
        }
      ]
      : [])
  ]

  const handleMarkAsReviewed = () => {
    setIsReviewModalVisible(true)
  }

  const handleReviewModalSubmit = async () => {
    try {
      const updatedClientData = {
        profile: {
          flags: {
            showTransactionScreen: true
          }
        }
      }

      await editClient(updatedClientData, person.id)
      message.success('Admin Review Marked Succesfully')
      setIsFlagChecked(true)
      setIsReviewModalVisible(false)
      setPendingCheck(false)
    } catch (error) {
      message.error('Failed to mark section as reviewed')
    }
  }

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked
    setIsFlagChecked(isChecked)

    if (isChecked) {
      setPendingCheck(true)
      setIsReviewModalVisible(true)
    } else {
      try {
        const updatedClientData = {
          profile: {
            flags: {
              showTransactionScreen: false
            }
          }
        }
        editClient(updatedClientData, person.id)
          .then(() => {
            message.success('Transaction screen visibility removed successfully')
            setIsFlagChecked(false)
          })
          .catch((error) => {
            message.error('Failed to remove transaction screen visibility')
          })
      } catch (error) {
        message.error('Failed to remove transaction screen visibility')
      }
    }
  }

  const handleModalCancel = () => {
    setIsReviewModalVisible(false)
    setIsDeleteConfirmModalVisible(false)
    if (pendingCheck) {
      setIsFlagChecked(false)
      setPendingCheck(false)
    }
  }

  const handdleCloseTransactionModal = () => {
    setIsModalVisible(false)
    setModalData({
      transactionType: '',
      transactionDate: new Date(),
      sessions: null,
      amount: null,
      reason: '',
      notes: null
    })
  }

  return (
    <div style={{ paddingRight: loginRole === 'User' ? 50 : 0 }}>
      {loginRole === 'User' && <h1 style={{ paddingTop: 50 }}>Service Sessions</h1>}

      <SessionSummary
        sessionsPurchased={sessionsPurchased}
        sessionsUsed={sessionsUsed}
      />

      <FilterPanel
        activeFilter={activeFilter}
        handleShowAll={handleShowAll}
        handleFilterChange={handleFilterChange}
        handleDateRangeChange={handleDateRangeChange}
        loginRole={loginRole}
        handleAddTransaction={handleAddTransaction}
        isFlagChecked={isFlagChecked}
        handleCheckboxChange={handleCheckboxChange}
        defaultStartDate={dateRange.startDate}
        defaultEndDate={dateRange.endDate}
      />

      <TransactionsTable data={transactionList} loading={loading} columns={columns} />

      <TransactionModal
        isVisible={isModalVisible}
        isEditMode={isEditMode}
        modalData={modalData}
        setModalData={setModalData}
        transactionReasons={transactionReasons}
        onClose={handdleCloseTransactionModal}
        onSubmit={handleModalSubmit}
      />

      <Modal
        title="Confirm Review"
        visible={isReviewModalVisible}
        onOk={handleReviewModalSubmit}
        onCancel={handleModalCancel}
        okText="Continue"
        cancelText="Cancel"
        className='review-modal'
      >
        <p>Marking this as reviewed will make the section visible to the client.</p>
      </Modal>

      <Modal
        title="Confirm Delete"
        visible={isDeleteConfirmModalVisible}
        onOk={handleDeleteTransactionConfirm}
        onCancel={handleModalCancel}
        okText="Continue"
        cancelText="Cancel"
        className='review-modal'
      >
        <p>Are you sure you want to delete.</p>
      </Modal>
    </div>
  )
}
